import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import React from "react";

import { browserName } from 'react-device-detect';

import Topbar from "../common/Topbar";
import { routes } from "../../routes";
import Footer from "../common/Footer";


const MainLayout = () => {
  return (
    <Box className={browserName}>
      <Box
        component="nav"
        sx={{
          flexShrink: 0
        }}
      >
        <Topbar collection={routes} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          top: sizeConfigs.topbar.height,
          minHeight: "5vh",
        }}
      >
        <Outlet />
      </Box>
      <Box
        component="footer"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default MainLayout;