import React from 'react';
import { Button, Container, ListItemButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { List, ListItem, ListItemText } from '@mui/material';
import Routes from "../../routes/routes";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "../elements/LanguageSwitcher";
import { BounceAnimation, MoveFadeInAnimation } from "../../hooks/useAnimations";

const containerStyleFooter = {
    width: '100%',
    height: '200px',
    backgroundColor: '#1962EF',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop: '15%',
}

const Footer = () => {

    const { t, i18n } = useTranslation();
    const listItems1 = Routes;

    const listItems2 = [
        {
            displayText: 'Terms and Conditions',
            path: `/${i18n.language}/terms-and-conditions-billezz`
        },
        {
            displayText: 'Privacy Policy',
            path: `/${i18n.language}/privacy-policy`,
        },
        {
            displayText: 'Cookie Policy',
            path: `/${i18n.language}/cookie-policy`,
        },
    ];

    const listItems3 = [
        {
            displayText: 'Home',
            path: `/${i18n.language}/`,
        },
        {
            displayText: 'Pricing',
            path: `/${i18n.language}/pricing`,
        },
        {
            displayText: 'Request a demo',
            path: `/${i18n.language}/demo`,
        },
    ];

    const curPath = window.location.pathname;
    const lang = curPath.split('/')[1];
    const activeLanguage = lang;

    return (
        <>
            <div className='relative spacing-sections' style={containerStyleFooter}>
                {/* <img className='absolute -left-12' style={circleStyle} src="/svg/yellow-circle.svg" alt="" />
                <img className='absolute -top-12 left-12 -z-20' src="/svg/blue-circle.svg" alt="" />
                <img className='absolute -top-28 left-1/3 -z-20' src="/svg/yellow-circle.svg" alt="" />
                <img className='absolute -top-12 right-1/3 -z-20' src="/svg/blue-circle.svg" alt="" /> */}
                <Container>
                    <MoveFadeInAnimation direction="down" distance="sm" amount={0.5}>
                        <h2 className='w-full text-2xl md:text-4xl lg:text-5xl font-semibold text-white'>{t('The Era of ’Eazy’ Subscription Management:')}</h2>
                        <div className='flex gap-3'>
                            <h2 className='text-2xl md:text-4xl lg:text-5xl font-thin text-white pb-6'>{t('BillEzz Makes It Happen!')}</h2>
                            <Button variant="contained" className='font-bold px-4 py-1 rounded-2xl rounded-bl-none h-1/3 my-auto btn-before-image'>
                                <a href={`/${activeLanguage}/pricing`}>{t('See our pricing')}</a>
                            </Button>
                        </div>
                    </MoveFadeInAnimation>
                </Container>
                <img className='absolute -top-24 lg:-top-12 md:-top-12 -mt-1.5 lg:-mt-1.5 right-12' src="/svg/characterZ.svg" alt="" />
                {/* <img className='absolute -bottom-14 right-1/4 -z-20' src="/svg/yellow-small-circle.svg" alt="" /> */}
            </div>
            <section className="bg-gradient-to-b to-transparent pb-32 flex">
                <Container>
                    <Grid container spacing={1} className='mt-10'>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Item>
                                <List className="pb-0 m-0">
                                    <Typography className="font-bold px-0 uppercase pb-2">
                                        {t('Company')}
                                    </Typography>

                                    {listItems1.map((item, index) => (
                                        item.showInMenu && item.showInMenu === true ?  (
                                        <ListItem key={index} className="p-0 m-0">
                                            {item.path ? (
                                                    <ListItemButton component={Link} to={item.path} aria-label={item.displayText} sx={{
                                                        paddingX: 0,
                                                    }}>
                                                        {t(item.displayText)}
                                                    </ListItemButton>
                                            ) : (
                                                <ListItemText className='px-0 py-0'
                                                    primary={t(item.displayText)}
                                                />
                                            )}
                                        </ListItem>
                                        ) : null
                                    ))}
                                </List>
                            </Item>
                        </Grid>
                    
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Item>
                                <List className="pb-0 m-0">
                                    <Typography className="font-bold px-0 uppercase pb-2">
                                        {t('Legal')}
                                    </Typography>

                                    {listItems2.map((item, index) => (
                                        <ListItem key={index} className="p-0 m-0">
                                            {item.path ? (
                                                <Button href={item.path} variant='text' aria-label={item.displayText} className='px-0 py-0 normal-case'>
                                                    <ListItemText 
                                                        primary={t(item.displayText)}
                                                    />
                                                </Button>
                                            ) : (
                                                <ListItemText className='px-0 py-0'
                                                    primary={t(item.displayText)}
                                                />
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </Item>
                        </Grid>
                    
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Item>
                                <List className="pb-0 m-0">
                                    <Typography className="font-bold px-0 uppercase pb-2">
                                        {t('More pages')}
                                    </Typography>

                                    {listItems3.map((item, index) => (
                                        <ListItem key={index} className="p-0 m-0 ">
                                            {item.path ? (
                                                <Button href={item.path} variant='text' aria-label={item.displayText} className='px-0 py-0 normal-case'>
                                                    <ListItemText 
                                                        primary={t(item.displayText)}
                                                    />
                                                </Button>
                                            ) : (
                                                <ListItemText className='px-0 py-0'
                                                    primary={t(item.displayText)}
                                                />
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </Item>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                backgroundColor: 'black',
                                color: 'yellow',
                                width: '33%',
                                height: '20%',
                                padding: '15px',
                                borderRadius: '5px',
                            }}
                            >
                            <Stack spacing={2} direction="column" alignItems="center">
                                    <MoveFadeInAnimation direction="down" distance="sm" amount={0.5}>
                                        <h1 className="w-full text-1xl md:text-3xl lg:text-4xl font-bold text-center text-white">
                                            <a href="https://app.billezz.com/auth/register">
                                                {t('Start your free trial')}
                                            </a>
                                        </h1>
                                    </MoveFadeInAnimation>
                                <Button variant="contained" className='font-bold px-4 py-1 rounded-2xl rounded-bl-none h-1/3 my-auto mt-3 btn-before-image' color="primary">
                                    <h1 className="w-full font-bold text-center">
                                        <a href="https://app.billezz.com/auth/register">
                                            {t('Start your set-up')}
                                        </a>
                                    </h1>
                                </Button>
                            </Stack>
                        </Button>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Item>
                                <List className="pb-0 m-0">
                                    <div className="flex justify-start md:justify-start lg:justify-start">
                                    <BounceAnimation delay={0.5}>
                                        <LanguageSwitcher />
                                    </BounceAnimation>
                                </div>
                                </List>
                            </Item>
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </>
    );
}
 
export default Footer;