import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';

const LanguageSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    const curPath = window.location.pathname;
    const lang = curPath.split('/')[1];
    const currentpath = window.location.pathname;

    if (lang !== lng) {
      const newpath = currentpath.replace(`/${lang}`, `/${lng}`);
      window.location.href = newpath;
    }
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="language"
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: 'black' }}
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        className='paper flex justify-center items-center'
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeLanguage('nl')}>
          <img className='h-auto flags' src="/images/nl-flag.png" alt="NL" style={{ background: 'transparent' }} />
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('en')}>
          <img className='h-auto flags' src="/images/en-flag.png" alt="EN" style={{ background: 'transparent' }} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;