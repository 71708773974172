import React from "react";

import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import Link from "@mui/material/Link";

import Hero from "../../components/elements/Hero";

type Props = any;

const CookieStatement = (props: Props) => {
  return (
    <React.Fragment>
      <section className="b-16 pt-40 lg:pb-40 flex">
        <Container>
          <Hero 
            title="Cookie Declaration" 
          />
        </Container>
      </section>

      <section className="pb-32 flex">
        <Container>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">1. The use of cookies</Typography>
                <p>Website: <Link href="https://billezz.com/" className="text-primary-yellow">https://billezz.com</Link></p>
                <p>Billezz uses cookies. A cookie is a simple small file that is sent along with pages from this website and/or Flash applications and is stored by your browser on the hard drive of your computer, mobile phone, smartwatch or tablet. The information stored therein can be sent back to our servers on a subsequent visit.</p>   
                <p>The use of cookies is of great importance for the proper running of our website, but also cookies of which you do not immediately see the effect are very important. Thanks to the (anonymous) input of visitors, we can improve the use of the website and make it more user-friendly.</p>   
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">2. Consent to the use of cookies</Typography>
                <p>Your permission is required for the use of certain cookies. We do this by means of a so-called cookie banner.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">3. The type of cookies used and their purposes</Typography>
                    <p>We use the following types of cookies:</p>
                    <p>Functional cookies: with this we can make the website function better and it is more user-friendly for the visitor. For example: we store your login details or what you have put in your shopping cart.</p>
                    <p>Anonymised analytical cookies: these ensure that an anonymous cookie is generated every time you visit a website. These cookies know whether you have visited the site before or not. Only on the first visit, a cookie is created, on subsequent visits, the existing cookie is used. This cookie is for statistical purposes only. This allows the following data to be collected:</p>
                    <ol>
                        <li>The number of unique visitors</li>
                        <li>How often users visit the site</li>
                        <li>Which pages users view</li>
                        <li>How long users view a particular page</li>
                        <li>At which page visitors leave the site</li>
                </ol>
                    <p>Analytical cookies: these ensure that a cookie is generated every time you visit a website. These cookies know whether you have visited the site before or not. Only on the first visit, a cookie is created, on subsequent visits, the existing cookie is used. This cookie is for statistical purposes only. This allows the following data to be collected, such as:</p>
                <ol>
                    <li>Which pages you viewed</li>
                    <li>How long you stayed on a certain page</li>
                    <li>At which page you left the site</li>
                    <li>How long users view a particular page</li>
                    <li>At which page visitors leave the site</li>
                </ol>
                    <p>Own Tracking cookies: this allows us to find out that you have also visited the relevant other website(s) from our network in addition to our website. The profile built up as a result is not linked to your name, address, e-mail address and the like, but only serves to match advertisements to your profile, so that they are as relevant to you as possible. We ask your permission for these cookies. These cookies are therefore not placed without your permission.</p>
                    <p>Tracking cookies from others: this keeps track of which pages you visit on the internet in order to build your personal profile. This profile is not linked to your name, address, e-mail address and the like as known to us, but only serves to match advertisements to your profile so that they are as relevant to you as possible. We ask your permission for these cookies. These cookies are therefore not placed without your permission.</p>
                    <p>Social media related cookies: social media such as Facebook and LinkedIn register which articles and pages you share via their social media sharing buttons. They may also contain tracking cookies that track your web browsing.</p>
                    <p>Site improvement cookies: with this we can test different versions of a web page to see which page is visited best.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">4. Your rights in relation to your data</Typography>
                <p>You have the right to inspect, rectify, limit and delete personal data. You also have the right to object to the processing of personal data and the right to data portability. You can exercise these rights by sending us an email at stephan@webbeukers.nl. To prevent misuse, we may ask you to identify yourself adequately. When it comes to access to personal data linked to a cookie, we ask you to send a copy of the cookie in question. You can find this in the settings of your browser.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">5. Block and delete cookies</Typography>
                <p>You can easily block and delete cookies yourself at any time via your internet browser. You can also set your internet browser in such a way that you receive a message when a cookie is placed. You can also indicate that certain cookies may not be placed. View the help function of your browser for this option. If you delete the cookies in your browser, this may have consequences for the pleasant use of this website.</p>
                <p>Some tracking cookies are placed by third parties that display advertisements to you via our website, among other things. You can delete these cookies centrally via <Link href="https://www.youronlinechoices.eu" className="text-primary-yellow">www.youronlinechoices.eu</Link>.</p>
                <p>Please be aware that if you do not want cookies, we can no longer guarantee that our Website will work properly. It is possible that some functions of the site are lost or even that you can no longer visit the website at all. In addition, refusing cookies does not mean that you will no longer see advertisements at all. The advertisements are then no longer tailored to your interests and can therefore be repeated more often.</p>
                <p>How you can adjust your settings differs per browser. Consult your browsers help function if necessary, or click on one of the icons below to go directly to your browsers manual.
                </p>
                <ol>
                    <li>Firefox: <Link href="https://support.mozilla.org/ en/kb/delete-cookies-data-delete-websites-saved" className="text-primary-yellow">https://support.mozilla.org/en/kb/cookies-delete-data-delete-websites-saved</Link></li>
                    <li>Google Chrome: <Link href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&amp;hl=en" className="text-primary-yellow">https://support.google.com/chrome/ answer/95647?co=GENIE.Platform=Desktop&amp;hl=en</Link></li>
                    <li>Internet Explorer: <Link href="https://support.microsoft.com/nl-nl/kb/278835" className="text-primary-yellow">https://support.microsoft.com/nl-nl/kb/278835</Link></li>
                    <li>Safari on smart phone: <Link href="https://support.apple.com/nl-nl /HT201265" className="text-primary-yellow">https://support.apple.com/nl-nl/HT201265</Link></li>
                    <li>Safari on Mac: <Link href="https://support.apple.com/nl-be /guide/safari/sfri11471/mac" className="text-primary-yellow">https://support.apple.com/en-us/guide/safari/sfri11471/mac</Link></li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">6. New developments and unforeseen cookies</Typography>
                <p>The texts of our website can be changed at any time due to continuous developments. This also applies to our cookie statement. Therefore, please review this statement regularly to stay informed of any changes.</p>
                <p>Blog articles may use content hosted on other sites and made accessible on <Link href="https://billezz.com/" className="text-primary-yellow">https://billezz.com</Link> by means of certain codes (embedded content). Consider, for example, YouTube videos. These codes often use cookies. However, we have no control over what these third parties do with their cookies.</p>
                <p>It is also possible that cookies are placed via our websites by others, of which we are not always aware. Do you encounter unforeseen cookies on our website that you cannot find in our overview? Let us know at: Stephan@webbeukers.nlYou can also contact us directly contact the third party and ask which cookies they placed, what the reason is, what the lifespan of the cookie is and how they have guaranteed your privacy. </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">7. Closing Remarks</Typography>
                <p>We will have to adjust these statements from time to time, for example when we adjust our website or change the rules regarding cookies. You can check this web page for the latest version.</p>
                <p>If you have any questions and/or comments, please contact: Stephan@webbeukers.nl</p>
            </div>
            <Typography><i>1 august 2020</i></Typography>
        </Container>
      </section>     
    </React.Fragment>
  )
};

export default CookieStatement;