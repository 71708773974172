import React from 'react';
// import { StrictMode } from "react";
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import './index.css';

// import CookieConsent from "react-cookie-consent";

import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';

import './i18n';

// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import ReactGA from 'react-ga4';
import ScrollToTop from './components/ScrollToTop';

// const TRACKING_ID = "G-MQ0CBKR7NL";
// ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//set background color
document.body.style.backgroundColor = "#FFFFFF";

root.render(
  <BrowserRouter>
    {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''} scriptProps={{async: false,defer: false,appendTo: "head",nonce: undefined,}}> */}
      <Provider store={store}>
      {/* <CookieConsent
          ariaAcceptLabel="Prima, verberg de pop-up"
          disableStyles={true}
          buttonText="Prima, verberg de pop-up"
        >
          <Typography variant="h2" className="text-white font-bold text-center">
            hallo! 
          </Typography>
          <Typography variant="h6" className="text-white text-center">
            Wij gebruiken cookies
          </Typography>
          <Typography className="text-white text-center">
            Wil je er meer over weten? Navigeer naar ons <Link href="/cookie-statement" className="text-primary-yellow">Cookie beleid</Link>
          </Typography>
        </CookieConsent> */}
        <CssBaseline />
      {/* <StrictMode> */}
        <ScrollToTop />
          <App />
        {/* </StrictMode> */}
      </Provider>
    {/* </GoogleReCaptchaProvider> */}
  </BrowserRouter>,
);