import React from 'react';
// import '../i18n'; // Dit initialiseert de i18next configuratie
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "../../components/elements/LanguageSwitcher";

import { Container } from '@mui/material';
// import { Box } from '@mui/system';
import { Button, Grid, Typography } from '@mui/material';

import { MoveFadeInAnimation, BounceAnimation } from '../../hooks/useAnimations';

import useMeta from "../../components/useMeta";

const curPath = window.location.pathname;
const lang = curPath.split('/')[1];
const activeLanguage = lang;

function BillezzSquare() {

  const { t, i18n } = useTranslation();

  return (
    useMeta({
      title: 'Turn billing into a breeze with billezz',
      description: 'BILLEZZ simplifies subscription management and invoicing, saving you time and effort. It offers efficiency, ease, and peace of mind, allowing you to focus on growing your business. Choose BILLEZZ for a hassle-free experience and to take your business to the next level.',
      keywords: 'Invoicing, eazy, work, Complex subscription management , Invoicing processes , BILLEZZ, Burden lifted, Growing your business, Simplicity, Efficiency, Tremendous time savings, One click , Hassle-free experience',
    }),
    <div>
      <Container className='spacing-sections'>
        <Grid container spacing={3} alignItems="center">
          
          <Grid item xs={12} md={6} className='flex flex-col'>
            <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
              <Typography variant="h6" className='font-semibold'>
                {t('Turn billing into a breeze with BillEzz:')}
              </Typography>
            </MoveFadeInAnimation>
            <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
              <Typography variant="h6" className='font-light' gutterBottom>
                {t('Invoicing has never been so ’eazy’!')}
              </Typography>
            </MoveFadeInAnimation>
            <MoveFadeInAnimation direction="down" distance="sm" amount={0.5}>
              <Typography paragraph>
                {t('Imagine billing becoming so effortless that you no longer have to sigh at the thought! With BILLEZZ, you won’t have to struggle with subscription management and intricate invoicing anymore. We’ve made it so ’eazy’ that it almost feels like lounging in a hammock on a sunny day while BILLEZZ does all the work for you. Save precious time, effort, and hassle as you let your business thrive. Ready to elevate your entrepreneurial journey to new heights? BILLEZZ makes it ’too eazy’!')}
              </Typography>
            </MoveFadeInAnimation>
            <Button variant="contained" className='font-bold px-4 py-1 rounded-2xl rounded-br-none mr-auto lg:ml-auto lg:mr-0 {--btn-before-image-blue-white--}' color="primary">
              <a href={`/${activeLanguage}/software`}>{t('Discover More')}</a>
            </Button>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <BounceAnimation delay={0}>
            <img
              src="/images/working-woman.png"
              alt="Woman working on laptop"
              style={{ width: '100%', borderRadius: '8px' }}
              />
            {/* <div className='relative md:hidden'>
                <img className='absolute -top-24 right-32 -z-20' src="/svg/blue-circle.svg" alt="" />
                <img className='absolute -top-64 -right-1/4 -z-20' src="/svg/yellow-circle.svg" alt="" />
                <img className='absolute -top-32 -right-44 -z-20' src="/svg/blue-circle.svg" alt="" />
            </div> */}
            </BounceAnimation>
          </Grid>
        
        </Grid>
      </Container>
      
      <Container className='spacings-sections'>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6} className='flex justify-center'>
            <BounceAnimation delay={0} extraclasses="w-5/12 md:w-7/12 lg:w-9/12">
              <img className='w-full'
                src="/images/face-graphic.jpg"
                alt="Graphical representation"
                style={{ borderRadius: '8px' }}
                />
            </BounceAnimation>
          </Grid>

          <Grid item xs={12} md={6}>
            <MoveFadeInAnimation direction="down" distance="sm" amount={0.5}>
              <Typography variant="h6" className='font-semibold' gutterBottom>
                {t('Simplify billing and subscription management ’to eazy’!')}
              </Typography>
            </MoveFadeInAnimation>
            <MoveFadeInAnimation direction="down" distance="sm" amount={0.5}>
              <Typography paragraph>
                {t('Why settle for complex subscription management and invoicing processes when you can make it much ’eazier’ on yourself? With BILLEZZ, the burden of subscription management is lifted off your shoulders, allowing you to focus on what truly matters: growing your business. What does BILLEZZ bring to the table? Simplicity. Efficiency. Tremendous time savings. It means you no longer have to navigate a maze of invoices and subscriptions but have everything under control with just one click. It means your customers can enjoy a hassle-free experience without administrative hiccups. And it means your business can thrive without you worrying about minor details or hassles. So, why choose BILLEZZ? Because it elevates your business to new heights. Because it saves you time and effort that you can invest elsewhere. Because it gives you peace of mind knowing everything runs smoothly. Ready to take your business to the next level? Choose BILLEZZ and make it ’to eazy’ on yourself!')}
              </Typography>
            </MoveFadeInAnimation>
            <Button variant="contained" className='font-bold text-xl px-8 py-2 rounded-2xl rounded-bl-none h-1/3 btn-before-image-2' color="primary">
              <a href={`/${activeLanguage}/demo`}>{t('Book a demo')}</a>
            </Button>
          </Grid>

        </Grid>
    </Container>
    </div>
  );
}
export default BillezzSquare;
