import { ListItemButton, ListItemText, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import RoomIcon from '@mui/icons-material/Room';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

type Props = {
  displayText : string,
  path: string
  icon: string
  isExternal: boolean
  onClick?: () => void
  isActive?: boolean
  style? : any
}

const TopbarItem = ({ displayText, path, icon, isExternal, isActive, style }: Props) => {
  <Link to={path} style={isActive ? { backgroundColor: 'yellow' } : {}}>
      {displayText}
  </Link>

  return (
    <ListItemButton
      {...isExternal ? {component: "a", href: path, target: "_blank"} : {component: Link, to: path}}
      sx={{ justifyContent: 'right', flexGrow: 0 }}
      aria-label={displayText}
      style={style}
    >
       <ListItemText
            disableTypography
            primary={
              <Typography className="" sx={{ fontWeight:"bold"}} style={style}>
                {icon !== "" ? (
                  icon === "LocalPhoneIcon" ? (
                    <LocalPhoneIcon />
                  ) : icon === "MailIcon" ? (
                    <MailIcon />
                  ) : icon === "RoomIcon" ? (
                    <RoomIcon />
                  ) : icon === "HelpOutlineIcon" ? (
                    <HelpOutlineIcon />
                  ) : null
                ) : displayText}

              </Typography>
            }
          />
    </ListItemButton>
  )
};

export default TopbarItem;