import React, { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function LanguagePath() {
    const { t, i18n } = useTranslation();
    
    const navigate = useNavigate();
    
    const curPath = window.location.pathname;
    console.log(curPath);
    
    
  useEffect(() => {
      //
    }, [i18n.resolvedLanguage]);
    return <Outlet />;
  }