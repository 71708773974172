import React from "react";
import Typography from "@mui/material/Typography";

import { Container } from "@mui/material";

import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import ContactForm from "../../components/elements/Forms/ContactForm";

import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import GoogleMapElement from "../../components/elements/GoogleMap";

import useMeta from "../../components/useMeta";

import { MoveFadeInAnimation, BounceAnimation } from '../../hooks/useAnimations';

type Props = any;

const ContactPage = (props: Props) => {

  const { t, i18n } = useTranslation();

  return (
    useMeta({
      title: 'Contact BillEzz: Simplify your subscription and invoice management',
      description: 'Get in touch with BillEzz to streamline your subscription and invoice management. Our team is here to help you automate your processes and make your business operations more efficient. Contact us today and discover how BillEzz can transform your management tasks.',
      keywords: 'BillEzz, Contact us, Subscription management, Invoice automation, Efficiency, Contact details, Phone number, Email address, Support, Business transformation.',
    }),
    <div>
      <Container className='mt-20 overflow-hidden'>
        <Grid xs={12} md={9} container spacing={1} alignItems="center" style={{ position: 'relative' }}>
          <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
            <Typography variant="h6" className="font-semibold">
              {t('Contact us:')}
            </Typography>
          </MoveFadeInAnimation>
        </Grid>

        <Grid xs={12} md={9} container spacing={1} alignItems="center" style={{ position: 'relative' }}>
          <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
            <Typography variant="h6" className="font-light">
              {t('We will help you to automate your subscription and invoice management')}
            </Typography>
          </MoveFadeInAnimation>
        </Grid>
      </Container>

      <Container className='mt-5'>
        <Grid container spacing={4} alignItems="center" style={{ position: 'relative' }}>
          <Grid item xs={12} md={6}>
            <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
              <ContactForm />
            </MoveFadeInAnimation>
          </Grid>
            <Grid item xs={12} md={6}>
              <BounceAnimation delay={0}>
                <CardMedia
                  component="img"
                  alt="Background"
                  image="/images/vision.png"
                  style={{ width: '100%', height: 'auto' }}
                  />
                  {/* <div className='relative'>
                      <img className='absolute -top-24 right-32 -z-20' src="/svg/blue-circle.svg" alt="" />
                      <img className='absolute -top-64 -right-20 -z-20' src="/svg/yellow-circle.svg" alt="" />
                      <img className='absolute -top-80 right-12 -z-20' src="/svg/blue-circle.svg" alt="" />
                  </div> */}
              </BounceAnimation>
            </Grid>
          </Grid>
      </Container>
      
      <Container className='spacing-sections'>
        <Grid container spacing={4} alignItems="center" style={{ position: 'relative' }}>
          <Grid item xs={12} md={6}>
            <Box>
                <ul>
                  <li className='custom-list-item'>
                  <Typography variant="body1">
                    <a href="tel:+31342226486">{t('PHONE - +31 (0) 342 22 64 86')}</a>
                  </Typography>
                  </li>
                  <hr className='border-black' />
                <li className='custom-list-item'>
                <Typography variant="body1">
                <Link 
                    href="mailto:support@billezz.com" 
                    sx={{ 
                      color: 'black',
                      textDecoration: 'none', 
                      '&:hover': { 
                        textDecoration: 'none',
                        color: 'black'
                      } 
                    }}
                  >
                    {t('SALES - SUPPORT@BILLEZZ.COM')}
                  </Link>
                </Typography>
                    </li>
                  <hr className='border-black' />
                    <li className='custom-list-item'>
                      <Typography variant="body1"> {t('ADDRESS - ANTHONIE FOKKERTSTRAAT 39G, 3772MP BARNEVELD, THE NETHERLANDS')}</Typography>
                    </li>
                  <hr className='border-black' />
              </ul>
            </Box>
          </Grid>
        </Grid>
                {/* <GoogleMapElement /> */}
      </Container>
    </div>
  )
};

export default ContactPage;