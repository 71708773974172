import { RouteType } from "./config";
import React from "react";
import HomePage from "../pages/home/HomePage";
import ContactPage from "../pages/contact/ContactPage";
import Software from "../pages/software/Software";
import Pricing from "../pages/pricing/Pricing";
import Demo from "../pages/demo/Demo";

import Blog from "../pages/blogs/AbonnementenBeheer";
import Blog2 from "../pages/blogs/HandleidingAbonnementenBeheer";
import Blog3 from "../pages/blogs/AutomatischeIncasso";
import Blog4 from "../pages/blogs/Factureren";
import Blog5 from "../pages/blogs/Voordelen";
import Blog6 from "../pages/blogs/Facturatie";
import Blog7 from "../pages/blogs/Automatisering";
import Blog8 from "../pages/blogs/LicentieAanbieders";
import Blog9 from "../pages/blogs/Efficientie";
import Blog10 from "../pages/blogs/Overzichten";
import Blog11 from "../pages/blogs/Klantenservice";
import Blog12 from "../pages/blogs/Functies";
import Blog13 from "../pages/blogs/Business";
import Blog14 from "../pages/blogs/Veiligheid";
import Blog15 from "../pages/blogs/Toekomst";

import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import CookieStatement from "../pages/cookieStatement/CookieStatement";
import TermsAndConditions from "../pages/termsAndConditions/TermsAndConditions";

const curPath = window.location.pathname;
const lang = curPath.split('/')[1];
const activeLanguage = lang;


const Routes: RouteType[] = [
  {
    index: true,
    path: `/${activeLanguage}/`,
    displayText: "Home",
    element: <HomePage />,
    state: "home",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/software`,
    displayText: "Software",
    element: <Software />,
    state: "software",
    showInMenu: true
  },
  {
    path: `/${activeLanguage}/pricing`,
    displayText: "Pricing",
    element: <Pricing />,
    state: "pricing",
    showInMenu: true
  },
  {
    path: `/${activeLanguage}/demo`,
    displayText: "Demo",
    element: <Demo />,
    state: "demo",
    showInMenu: true
  },
  {
    path: `/${activeLanguage}/contact`,
    displayText: "Contact",
    element: <ContactPage />,
    state: "contact",
    showInMenu: true
  },
  {
    path: `/${activeLanguage}/abonnementen-beheer`,
    displayText: "BLOG",
    element: <Blog />,
    state: "blog",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/handleiding-abonnementen-beheer`,
    displayText: "BLOG2",
    element: <Blog2 />,
    state: "blog2",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/automatische-incasso`,
    displayText: "BLOG3",
    element: <Blog3 />,
    state: "blog3",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/factureren`,
    displayText: "BLOG4",
    element: <Blog4 />,
    state: "blog4",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/voordelen`,
    displayText: "BLOG5",
    element: <Blog5 />,
    state: "blog5",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/facturatie`,
    displayText: "BLOG6",
    element: <Blog6 />,
    state: "blog6",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/automatisering`,
    displayText: "BLOG7",
    element: <Blog7 />,
    state: "blog7",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/licentie-aanbieders`,
    displayText: "BLOG8",
    element: <Blog8 />,
    state: "blog8",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/efficientie`,
    displayText: "BLOG9",
    element: <Blog9 />,
    state: "blog9",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/overzichten`,
    displayText: "BLOG10",
    element: <Blog10 />,
    state: "blog10",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/klantenservice`,
    displayText: "BLOG11",
    element: <Blog11 />,
    state: "blog11",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/functies`,
    displayText: "BLOG12",
    element: <Blog12 />,
    state: "blog12",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/business`,
    displayText: "BLOG13",
    element: <Blog13 />,
    state: "blog13",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/veiligheid`,
    displayText: "BLOG14",
    element: <Blog14 />,
    state: "blog14",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/toekomst`,
    displayText: "BLOG15",
    element: <Blog15 />,
    state: "blog15",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/cookie-policy`,
    displayText: "Cookie Statement",
    element: <CookieStatement />,
    state: "cookieStatement",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/privacy-policy`,
    displayText: "Privacy Policy",
    element: <PrivacyPolicy />,
    state: "privacyPolicy",
    showInMenu: false
  },
  {
    path: `/${activeLanguage}/terms-and-conditions-billezz`,
    displayText: "Terms and Conditions",
    element: <TermsAndConditions />,
    state: "termsAndConditions",
    showInMenu: false
  }
];

export default Routes;