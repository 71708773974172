import { AppBar, Grid, List, Stack } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import React, { useState, useEffect } from "react";
import TopbarItem from "./TopbarItem";
import Routes from "../../routes/routes";
import { useTranslation } from 'react-i18next';

import "../../i18n";

import { Container } from "@mui/material";

import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { Link, useLocation } from 'react-router-dom';

type Props = any;

const Topbar = (props: Props) => {
  const { t, i18n } = useTranslation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };

  return (
    <div className="bg-dark-blue w-full">
      <AppBar
        sx={{
          boxShadow: "unset",
          backgroundColor: "transparent",
          color: colorConfigs.topbar.color,
          position: "relative",
        }}
      >
      <div className="lg:hidden block z-20">
        <IconButton onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon className="fill-white" />}
        </IconButton>
      </div>
        
      <div className={`transition-transform transform fixed top-0 left-0 z-10 w-full h-25 bg-white p-5 ${isMobileMenuOpen ? 'translate-y-0' : '-translate-y-full'}`}>
        <List style={{ flexDirection: 'column', gap: '1rem' }}>
          {Routes.map((item, index) => (
            item.showInMenu && (
              <Link
                key={index}
                to={item.path}
                onClick={toggleMobileMenu}
                style={{
                  display: 'block',
                  padding: '0.5rem 1rem',
                  borderBottom: '1px solid #e0e0e0',
                }}
              >
                {t(item.displayText)}
              </Link>
            )
          ))}
            
          <a 
            href="https://app.billezz.com/auth/login" 
            style={{
              display: 'block',
              padding: '0.5rem 1rem',
              borderBottom: '1px solid #e0e0e0',
              color: 'black',
            }}
            onClick={toggleMobileMenu}
          >
            Login
          </a>

          <a 
            href="https://app.billezz.com/auth/register" 
            style={{
              display: 'block',
              padding: '0.5rem 1rem',
              borderBottom: '1px solid #e0e0e0',
              color: 'black',
            }}
            onClick={toggleMobileMenu}
          >
            Register
          </a>
        </List>
      </div>

        <Container>
          {/* <MoveFadeInAnimation direction="right" distance="sm" amount={0.8}> */}
            <div className="flex justify-end gap-1 mt-2">
              <a href="https://app.billezz.com/auth/login" className="text-white uppercase lg:block hidden">{t('Login')}</a>
              <p className="text-white uppercase lg:block hidden">|</p>
              <a href="https://app.billezz.com/auth/register" className="text-white font-semibold uppercase lg:block hidden">{t('Register')}</a>
            </div>
          {/* </MoveFadeInAnimation> */}
          <Grid container className="lg:py-10 py-2" direction="row" justifyContent="flex-start" columnSpacing={0} mt={0}>
            <div className='relative mx-auto'>
              {/* <MoveFadeInAnimation direction="down" distance="sm" amount={0.5}> */}
                <h1 className="w-full text-5xl lg:text-8xl md:text-6xl font-light text-center text-white">
                  <a href="/" className="flex items-center">
                    Bill<span className="font-bold">Ezz</span>
                  </a>
                </h1>
              {/* </MoveFadeInAnimation> */}

              {/* <BounceAnimation delay={0}> */}
              <Button
                variant="contained" 
                className={`absolute font-semibold px-4 py-1 rounded-2xl rounded-bl-none h-1/3 btn-before-image whitespace-nowrap lg:block hidden ${
                  currentPath === `/${i18n.language}/` ? 'mb-2 mt-0 -mr-[0.1rem] -right-[29%] -top-3' :
                  currentPath === `/${i18n.language}/software` ? 'mb-2 mt-0 -mr-[0.1rem] -right-24 -top-3' : 
                  currentPath === `/${i18n.language}/pricing` ? 'mb-2 mt-0 -mr-[1rem] -right-16 -top-3' : 
                  currentPath === `/${i18n.language}/demo` ? 'mb-2 mt-0 -mr-[0.2rem] -right-16 -top-3' : 
                  currentPath === `/${i18n.language}/contact` ? 'mb-2 mt-0 -mr-[0.4rem] -right-20 -top-3' : ''}`
              }>
                { currentPath === `/${i18n.language}/` ? 'Too eazy' :
                  currentPath === `/${i18n.language}/software` ? t('Software') : 
                  currentPath === `/${i18n.language}/pricing` ? t('Pricing') : 
                  currentPath === `/${i18n.language}/demo` ? t('Demo') :
                  currentPath === `/${i18n.language}/contact` ? t('Contact') : ''}
              </Button>
              {/* </BounceAnimation> */}
            </div>
          </Grid>
        </Container>
      
        <div className="bg-primary-grey w-full lg:block hidden">
          <Container>
            <Grid container direction="row" justifyContent="flex-start" columnSpacing={0} mt={0} height={60}>
                <Grid item container className="w-full" xs={12}>
                  <List component={Stack} direction="row" className="mx-auto">
                    {Routes.map((item, index) => (
                      item.showInMenu ? (
                        item.displayText && !item.index ? (
                          <div 
                            key={index}
                            style={{ 
                              margin : "auto",
                            }}
                          >
                            <TopbarItem
                              path={item.path}
                              style={{
                                backgroundColor: item.path === currentPath ? "yellow" : "transparent",
                                color: item.path === currentPath ? "black" : "white",
                                padding: "0px 14px",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                                borderRadius: "10px 10px 0px 10px",
                              }}
                              icon=""
                              displayText={t(item.displayText)}
                              isExternal={false}
                            />
                          </div>
                        ) : null
                      ) : null
                    ))}
                  </List>
                </Grid>
            </Grid>
          </Container>
        </div>
      </AppBar>
    </div>
  )
};

export default Topbar;