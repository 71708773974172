import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type ObjectType = {
  title: string,
  description: string,
  keywords: string,
}

export default function useMeta(metaObject: ObjectType) {
  const defaultTitle = 'Billezz';
  const defaultDescription = 'BillEzz is een innovatieve oplossing voor abonnementenbeheer die bedrijven helpt om hun abonnementen te beheren en te laten groeien.';
  const defaultKeywords = 'BillEzz, abonnementenbeheer, abonnementenbeheer software';
  
  useEffect(() => {
    document.title = metaObject.title || defaultTitle;
    document.querySelector('meta[name="title"]')!.setAttribute('content', metaObject.title || defaultTitle);
    document.querySelector('meta[name="description"]')!.setAttribute('content', metaObject.description || defaultDescription);
    document.querySelector('meta[name="keywords"]')!.setAttribute('content', metaObject.keywords || defaultKeywords);
  }, [defaultTitle, metaObject.title, defaultDescription, metaObject.description, defaultKeywords, metaObject.keywords]);

  return null;
}