import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import LanguagePath from "./components/LanguagePath";

import { routes } from "./routes";
import { register } from 'swiper/element/bundle';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
          backgroundColor: '#1962EF'
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: '6px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d1d1d',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#F3F808',
    },
  },
  typography: {
    fontFamily: [
      'Blinker',
      'sans-serif',
    ].join(','),
  },
});

register();

function App() {
  const curPath = window.location.pathname;
  const lang = curPath.split('/')[1];

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path={`${lang ?? 'en'}/`} element={<LanguagePath />}>
            {routes}
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
