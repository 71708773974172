import React from 'react';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { CardMedia } from '@mui/material';

import { useTranslation } from 'react-i18next';

import ContactForm from "../../components/elements/Forms/ContactForm";

import { MoveFadeInAnimation, BounceAnimation } from '../../hooks/useAnimations';
import useMeta from '../../components/useMeta';

function Demo() {

  const { t, i18n } = useTranslation();
  return (
    useMeta({
      title: 'Request a BillEzz demo: Simplify subscription and invoice management',
      description: 'Experience the power of BillEzz with a personalized demo. Our experts will guide you in automating your subscription and invoice management, making your business operations smoother and more efficient. Request a demo today to see how BillEzz can transform your processes.',
      keywords: 'BillEzz, Demo request, Subscription management, Invoice automation, Efficiency, Personalized guidance, Business transformation.',
    }),
    <div>
      <Container className='mt-20 overflow-hidden'>
        <Grid xs={12} md={9} container spacing={1} alignItems="center" style={{ position: 'relative' }}>
          <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
            <Typography variant="h6" className="font-semibold">
              {t('Request a demo:')}
            </Typography>
          </MoveFadeInAnimation>
        </Grid>

        <Grid xs={12} md={9} container spacing={1} alignItems="center" style={{ position: 'relative' }}>
          <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
            <Typography variant="h6" className="font-light">
              {t('We will help you to automate your subscription and invoice management')}
            </Typography>
          </MoveFadeInAnimation>
        </Grid>
      </Container>

      <Container className='mt-5'>
        <Grid container spacing={4} alignItems="center" style={{ position: 'relative' }}>
          <Grid item xs={12} md={6}>
            <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
              <ContactForm />
            </MoveFadeInAnimation>
          </Grid>
            <Grid item xs={12} md={6}>
              <BounceAnimation delay={0}>
                <CardMedia
                  component="img"
                  image="/images/jungle.png"
                  style={{ width: '100%', height: 'auto' }}
                  />
                  {/* <div className='relative'>
                      <img className='absolute -top-24 right-32 -z-20' src="/svg/blue-circle.svg" alt="" />
                      <img className='absolute -top-64 -right-20 -z-20' src="/svg/yellow-circle.svg" alt="" />
                      <img className='absolute -top-80 right-12 -z-20' src="/svg/blue-circle.svg" alt="" />
                  </div> */}
              </BounceAnimation>
            </Grid>
          </Grid>
      </Container>
    </div>
  );
}
export default Demo;