import React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';

import { useTranslation } from 'react-i18next';

import { MoveFadeInAnimation, BounceAnimation } from '../../hooks/useAnimations';

import useMeta from "../../components/useMeta";

const curPath = window.location.pathname;
const lang = curPath.split('/')[1];
const activeLanguage = lang;

function Software() {

  const { t, i18n } = useTranslation();

  return (
    useMeta({
      title: 'Unlocking effortless subscription management and invoicing with BillEzz',
      description: 'Discover BillEzz: Your solution for effortless subscription management and invoicing. Explore clear dashboards, efficient service, customer management, secure account handling, seamless payment processing, and automatic collection. Book a demo and transform your business now!"',
      keywords: 'Billezz, subscription, invoicing, dashboards, service management, customer management, account management, payment processing, automatic collection, security',
    }),
    <div>
      <Container className='mt-20 overflow-hidden'>
        <Grid xs={12} md={8} container spacing={1} alignItems="center" style={{ position: 'relative' }}>
          <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
            <Typography variant="h6" className='font-semibold'>
              {t('Explore BillEzz:')}
            </Typography>
            </MoveFadeInAnimation>
          <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
            <Typography variant="h6" className='font-light' gutterBottom>
              {t('Subscription management and invoicing, as eazy as a summer breeze!')}
            </Typography>
          </MoveFadeInAnimation>
        </Grid>
      </Container>

      <Container className='mt-5 mb-10'>
        <Grid container spacing={1} alignItems="center" style={{ position: 'relative' }}>
            <Grid item xs={12} md={6} className='flex flex-col'>
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography variant="body1" paragraph>
                  {t('Welcome to BillEzz - the future of subscription management and invoicing. We’ve made managing subscriptions and invoicing as ’eazy’ as it gets, so you can focus on what truly matters: growing your business. Let’s take a look at what BillEzz can do for you.')}
                </Typography>
              </MoveFadeInAnimation>
            <Button variant="contained" className='rounded-3xl font-bold mr-auto lg:ml-auto lg:mr-0 lg:mt-8 md:mt-0 rounded-br-none {{--btn-before-image-blue-photo-2--}}' color="primary"><a href="/pricing">{t('See pricing')}</a></Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <BounceAnimation delay={0}>
              <CardMedia
                component="img"
                alt="Background"
                image="/images/ViewWatcher.png"
                style={{ width: '100%', height: 'auto' }}
                />
                {/* <div className='relative'>
                    <img className='absolute -top-24 right-32 -z-20' src="/svg/blue-circle.svg" alt="" />
                    <img className='absolute -top-64 right-32 -z-20' src="/svg/yellow-circle.svg" alt="" />
                    <img className='absolute -top-64 right-12 -z-20' src="/svg/blue-circle.svg" alt="" />
                </div> */}
            </BounceAnimation>
          </Grid>
        </Grid>
        </Container>
          <Container className='spacing-sections'>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
                    <Typography variant="h6" className="font-bold yellow-underline" gutterBottom>
                      {t('Clear dashboard:')}
                    </Typography>
                      <Box>
                          <ul>
                            <li className='custom-list-item'>
                              <Typography variant="body1"> {t('Get a snapshot of your business performance at a glance')}</Typography>
                            </li>
                            <li className='custom-list-item'>
                              <Typography variant="body1"> {t('Track your revenue, product categories, and customers with a metric table.')}</Typography>
                            </li>
                          </ul>
                      </Box>
                  </MoveFadeInAnimation>
                </Grid>
                  <Grid item xs={12} md={4}>
                    <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
                      <Typography variant="h6" className="font-bold yellow-underline" gutterBottom>
                        {t('Service management:')}
                      </Typography>
                    <Box>
                      <ul>
                        <li className='custom-list-item'>
                          <Typography variant="body1"> {t('Effortlessly create, edit, and delete services')}</Typography>
                        </li>
                        <li className='custom-list-item'>
                          <Typography variant="body1"> {t('Upgrade or downgrade services with a single click')}</Typography>
                        </li>
                        <li className='custom-list-item'>
                          <Typography variant="body1"> {t('Set billing intervals - monthly or annually')}</Typography>
                        </li>
                      </ul>
                    </Box>
                  </MoveFadeInAnimation>
                </Grid>
                  <Grid item xs={12} md={4}>
                    <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
                      <Typography variant="h6" className="font-bold yellow-underline" gutterBottom>
                        {t('Customer management:')}
                      </Typography>
                      <Box>
                        <ul>
                          <li className='custom-list-item'>
                            <Typography variant="body1"> {t('Create, edit, and delete customer profiles with ease.')}</Typography>
                          </li>
                          <li className='custom-list-item'>
                            <Typography variant="body1"> {t('Easily assign services to customers.')}</Typography>
                          </li>
                          <li className='custom-list-item'>
                            <Typography variant="body1"> {t('Personalize business information for individual customers.')}</Typography>
                          </li>
                          <li className='custom-list-item'>
                            <Typography variant="body1"> {t('VAT shifting for international customers, such as Belgian customers.')}</Typography>
                          </li>
                        </ul>
                      </Box>
                    </MoveFadeInAnimation>
                </Grid>
                  <Grid item xs={12} md={4}>
                    <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
                      <Typography variant="h6" className="font-bold yellow-underline" gutterBottom>
                        {t('Account management:')}
                      </Typography>
                      <Box>
                        <ul>
                          <li className='custom-list-item'>
                            <Typography variant="body1"> {t('Allow customers with multiple companies to switch between accounts effortlessly')}</Typography>
                          </li>
                          <li className='custom-list-item'>
                            <Typography variant="body1"> {t('Unique passwordless login system for enhanced security')}</Typography>
                          </li>
                        </ul>
                      </Box>
                    </MoveFadeInAnimation>
                </Grid>
                  <Grid item xs={12} md={4}>
                    <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
                      <Typography variant="h6" className="font-bold yellow-underline" gutterBottom>
                        {t('Payment processing:')}
                      </Typography>
                      <Box>
                      <ul>
                        <li className='custom-list-item'>
                          <Typography variant="body1"> {t('Seamlessly integrate with mollie for payments')}</Typography>
                        </li>
                        <li className='custom-list-item'>
                          <Typography variant="body1"> {t('Automatic collection with repeated attempts for missed payments')}</Typography>
                        </li>
                        <li className='custom-list-item'>
                          <Typography variant="body1"> {t('Manual online payment option')}</Typography>
                        </li>
                        <li className='custom-list-item'>
                          <Typography variant="body1"> {t('Real-time display of invoice status')}</Typography>
                        </li>
                      </ul>
                    </Box>
                  </MoveFadeInAnimation>
                </Grid>
                  <Grid item xs={12} md={4}>
                    <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
                      <Typography variant="h6" className="font-bold yellow-underline" gutterBottom>
                        {t('Automatic collection:')}
                      </Typography>
                      <Box>
                        <ul>
                          <li className='custom-list-item'>
                            <Typography variant="body1"> {t('Our system automatically attempts new collections for failed payments')}</Typography>
                          </li>
                        </ul>
                      </Box>
                    </MoveFadeInAnimation>
                </Grid>
            </Grid>
          </Container>
    
        <Container className='spacing-sections'>
          <Grid container spacing={3} alignItems="center" style={{ position: 'relative' }}>
            <Grid item xs={12} md={6}>
              <BounceAnimation delay={0}>
                <div className='relative mx-auto'>
                    <h1 className="w-full text-8xl font-light text-center text-blue">
                      Bill<span className="font-bold">Ezz</span>
                    </h1>
                </div>
              </BounceAnimation>
            </Grid>
            <Grid item xs={12} md={6}>
              <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
                <Typography variant="h6" className='font-medium' gutterBottom>
                  {t('Why wait? Discover how BillEzz can transform your business right now!')}
                </Typography>
              </MoveFadeInAnimation>
              <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
                <Typography variant="body1" paragraph>
                  {t('Book a demo and see it in action, or start with our Free Tier to immediately reap the benefits. Your dream of effortless subscription management begins here and now with BillEzz.')}
                </Typography>
              </MoveFadeInAnimation>
              <Button variant="contained" className='font-bold text-xl px-8 py-2 rounded-2xl rounded-bl-none h-1/3 btn-before-image-2' color="primary">
                <a href={`/${activeLanguage}/demo`}>{t('Book a demo')}</a>
              </Button>
            </Grid>
          </Grid>
        </Container>
    </div>
  );
}
export default Software;