import React from 'react';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { CardMedia } from '@mui/material';
import { Button } from '@mui/material';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useTranslation } from 'react-i18next';

import { MoveFadeInAnimation, BounceAnimation } from '../../hooks/useAnimations';

import useMeta from "../../components/useMeta";

function createData(
  detail : string,
  freeTier: string,
  starterTier: string,
  GrowthTier: string,
  EnterpriseTier: string,
) {
  return { detail, freeTier, starterTier, GrowthTier, EnterpriseTier};
}

const rows = [
  createData('Fixed price P.M.', 'On request', 'On request', 'On request', 'On request'),
  createData('Clients', '5 Clients', 'Up to 50', '51 to 200', 'On request'),
  createData('Products', '5 Products', '50 Products', 'Unlimited', 'Unlimited'),
  createData('Categories', '2 Categories', '3 Categories', 'Unlimited', 'Unlimited'),
  createData('Dashboard', 'Basic', 'Advanced', 'Advanced', 'Advanced'),
  createData('Mollie Key Int.', 'Yes', 'Yes', 'Yes', 'Yes'),
  createData('Direct Debit', 'Transaction Fee', 'Transaction Fee', 'Transaction Fee', 'Transaction Fee'),
  createData('Multi level users', '-', 'No', 'Yes', 'Yes'),
  createData('BTW Settings', '-', '-', 'Yes', 'Yes'),
  createData('Invoice BCC-Adress', '-', '-', 'Yes', 'Yes'),
  createData('Whitelabel Set-up', '-', 'On request', 'On request', 'Free'),
  createData('Premium Support', '-', '-', 'Yes', 'Yes'),
];

const curPath = window.location.pathname;
const lang = curPath.split('/')[1];
const activeLanguage = lang;

function Pricing() {

  const { t, i18n } = useTranslation();
  return (
    useMeta({
      title: 'BillEzz pricing and features: Streamlining subscription management for your business',
      description: 'Discover BillEzz pricing and features designed to simplify subscription management. Choose the right plan for your business needs, from our Free Tier to Enterprise Tier. Start streamlining your subscriptions with BillEzz today!',
      keywords: 'BillEzz, Pricing plans, Subscription management, Affordable options, Free Tier, Starter Tier, Growth Tier, Enterprise Tier, Streamlining subscriptions, Business transformation',
    }),
    <div>
      <Container className='mt-20 overflow-hidden'>
      <Grid xs={12} md={8} container spacing={1} alignItems="center" style={{ position: 'relative' }}>
        <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
          <Typography variant="h6" className='font-semibold'>
            {t('Explore Our Pricing Plans for Smart Subscription Management')}
          </Typography>
          </MoveFadeInAnimation>
      </Grid>
    </Container>

    <Container className='mt-5 mb-10'>
      <Grid container spacing={1} alignItems="center" style={{ position: 'relative' }}>
          <Grid item xs={12} md={6} className='flex flex-col'>
            <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
              <Typography variant="body1" paragraph>
                {t('Explore pricing plans tailored to your needs and leave cost worries behind. BillEzz makes subscription management easy and affordable, allowing you to focus on what truly matters: growth and success.')}
              </Typography>
            </MoveFadeInAnimation>
          <Button variant="contained" className='rounded-3xl font-bold mr-auto lg:ml-auto lg:mr-0 lg:mt-8 md:mt-0 rounded-br-none {{--btn-before-image-blue-photo-2--}}' color="primary"><a href="https://app.billezz.com/auth/register">{t('Start a free trial')}</a></Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <BounceAnimation delay={0}>
            <CardMedia
              component="img"
              alt="Background"
              image="/images/ViewWatcher.png"
              style={{ width: '100%', height: 'auto' }}
              />
              {/* <div className='relative'>
                  <img className='absolute -top-24 right-32 -z-20' src="/svg/blue-circle.svg" alt="" />
                  <img className='absolute -top-64 right-32 -z-20' src="/svg/yellow-circle.svg" alt="" />
                  <img className='absolute -top-64 right-12 -z-20' src="/svg/blue-circle.svg" alt="" />
              </div> */}
          </BounceAnimation>
        </Grid>
      </Grid>
      </Container>
      <div>
        <Container className='spacing-sections'>
          <Grid container spacing={1} alignItems="center" style={{ position: 'relative' }}>
                <TableContainer>
                    <Table size="small" aria-label="a dense table"> 
                        <TableHead className='relative'>
                            <TableRow>
                                <TableCell className='sticky left-0 bg-white'>.</TableCell>
                                <TableCell className='font-bold' align="right">{t('Free tier')}</TableCell>
                                <TableCell className='font-bold' align="right">{t('Starter tier')}</TableCell>
                                <TableCell className='font-bold' align="right">{t('Growth tier')}</TableCell>
                                <TableCell className='font-bold' align="right">{t('Enterprise tier')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.detail}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    className='relative'
                                  >
                                    <TableCell component="th" scope="row" className='sticky left-0 bg-white min-w-[45vw] lg:min-w-fit font-bold'>
                                        {row.detail}
                                    </TableCell>
                                    <TableCell className='min-w-[50vw] lg:min-w-full' align="right">{row.freeTier}</TableCell>
                                    <TableCell className='min-w-[50vw] lg:min-w-full' align="right">{row.starterTier}</TableCell>
                                    <TableCell className='min-w-[50vw] lg:min-w-full' align="right">{row.GrowthTier}</TableCell>
                                    <TableCell className='min-w-[50vw] lg:min-w-full' align="right">{row.EnterpriseTier}</TableCell>
                                </TableRow>
                            ))}
                          </TableBody>
                    </Table>
              </TableContainer>
              </Grid>
          </Container>
        </div>

      <Container className='spacing-sections'>
        <Grid container spacing={3} alignItems="center" style={{ position: 'relative' }}>
          <Grid item xs={12} md={6}>
            <BounceAnimation delay={0}>
              <div className='relative mx-auto'>
                  <h1 className="w-full text-8xl font-light text-center text-blue">
                    Bill<span className="font-bold">Ezz</span>
                  </h1>
              </div>
            </BounceAnimation>
          </Grid>
          <Grid item xs={12} md={6}>
            <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
              <Typography variant="h6" className='font-medium' gutterBottom>
                {t('Why wait? Discover how BillEzz can transform your business right now!')}
              </Typography>
            </MoveFadeInAnimation>
            <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
              <Typography variant="body1" paragraph>
                {t('Book a demo and see it in action, or start with our Free Tier to immediately reap the benefits. Your dream of effortless subscription management begins here and now with BillEzz.')}
              </Typography>
            </MoveFadeInAnimation>
            <Button variant="contained" className='font-bold text-xl px-8 py-2 rounded-2xl rounded-bl-none h-1/3 btn-before-image-2' color="primary">
              <a href={`/${activeLanguage}/demo`}>{t('Book a demo')}</a>
            </Button>
          </Grid>
        </Grid>
      </Container>
  </div>
  );
}
export default Pricing;